import './App.scss';
import { Routes, Route, Navigate } from "react-router-dom";
import TrulymadlyMatrimony from './pages/TrulymadlyMatrimony';
// import Contact from './pages/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import Article1 from './pages/Article1';
import Article2 from './pages/Article2';
import Article3 from './pages/Article3';
import Article4 from './pages/Article4';

function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<><Header /><TrulymadlyMatrimony/><Footer /></>} />
        <Route path="/positive-affirmations" element={<><Header /><Article1/><Footer /></>} />
        <Route path="/social-impact" element={<><Header /><Article2/><Footer /></>} />
        <Route path="/overcome-procrastination" element={<><Header /><Article3/><Footer /></>} />
        <Route path="/stress-reduction" element={<><Header /><Article4/><Footer /></>} />
        {/* <Route path="/contact" element={<><Header /><Contact/><Footer /></>} /> */}
        {/* <Route path="/privacy-policy" element={<><Header /><PrivacyPolicy/><Footer /></>} />
        <Route path="/terms" element={<><Header /><TermsConditions/><Footer /></>} />
        <Route path="/thank-you" element={<><Header /><ThanksYouPage /><Footer /></>} />
        <Route path="/safety-guidelines" element={<><Header /><SafetyGuidelines /><Footer /></>} /> */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
}

export default App;
