import './styles.scss';
import React, { useEffect, useState } from 'react';
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import BlogImg1 from "../../images/article-1.webp";
import BlogImg2 from "../../images/article-2.webp";
import BlogImg3 from "../../images/article-3.webp";
import BlogImg4 from "../../images/article-4.webp";
// import SampleCouplePic from "../../images/central-ppl-group.webp";

function TrulymadlyMatrimony() {

    // const ref1 = React.useRef(null);
    // const ref2 = React.useRef(null);
    // const formRef = React.useRef(null);
    // const navigate = useNavigate();

    useEffect(() => {
        // ref1.current.click();
        // ref2.current.click();
    }, []);

    // const [formError, toggleFormError] = useState(false);

    // const checkFormValidation = () => {
    //     if(!formRef.current.checkValidity()) {
    //         toggleFormError(true);
    //     } else {
    //         toggleFormError(false);
    //         navigate("/thank-you");
    //     }
    // }

    return (
        <div>
            {/* <div className="row mx-0 mt-5 bg-red-blue-gradient">
                <div className="col-md-6 col-sm-12 px-3 d-flex justify-content-center align-items-center">
                    <div className="carousal-home-page">
                        <div id="carouselExampleIndicators" className="carousel slide overflow-hidden" data-bs-ride="true" data-bs-interval="2500">
                            <div className="home-view-carousal carousel-inner">
                                <div className="carousel-item active">
                                    <img src="https://cdni.trulymadly.com/tm-static-assets-production/images/admin/jodi-1-v3.webp" className="d-block w-100" alt="..." />
                                </div>
                                <div className="carousel-item">
                                    <img src="https://cdni.trulymadly.com/tm-static-assets-production/web/jodi-2-v2.webp" className="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <ol className="carousel-indicators position-absolute login-image-carousel">
                                <li ref={ref1} data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" />
                                <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" />
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 position-relative col-sm-12 p-0 py-md-4 d-flex flex-column justify-content-center">
                    <div className="form-section">
                        <form ref={formRef}>
                            <div className="mb-3">
                                <label htmlFor="inputName" className="form-label">Name</label>
                                <input type="text" className={"form-control" + (formError ? " form-error" : "")} id="inputName" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inputEmail" className="form-label">Email address</label>
                                <input type="email" className={"form-control" + (formError ? " form-error" : "")} id="inputEmail" aria-describedby="emailHelp" required />
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Select Gender</label>
                                <select className={"form-select form-select-md" + (formError ? " form-error" : "")} aria-label=".form-select-sm" defaultValue="none" required>
                                    <option value="none" disabled>Click to select</option>
                                    <option value="m">Male</option>
                                    <option value="f">Female</option>
                                </select>
                            </div>

                            <button type="button" onClick={() => checkFormValidation()} className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div> */}

            <div className="col-md-9 mt-5 mx-auto d-flex flex-wrap mb-md-4">
                <div className="col-md-6 d-flex flex-column post-item">
                    <div className="post-image">
                        <img src={BlogImg1} className="d-block w-100 h-100" alt="..." />
                    </div>

                    <div className="post-title">
                        The Power of Positive Affirmations in Boosting Self-Esteem
                    </div>

                    <div className="post-subtitle">
                        Positive affirmations are powerful tools that can 
                        significantly impact our self-esteem and overall...
                    </div>

                    <Link to="/positive-affirmations" className="post-button">
                        Read more
                        <span className="post-btn-icon">{'>'}</span>
                    </Link>
                    
                </div>

                <div className="col-md-6 d-flex flex-column post-item">
                    <div className="post-image">
                        <img src={BlogImg2} className="d-block w-100 h-100" alt="..." />
                    </div>

                    <div className="post-title">
                        Understanding the Impact of Social Media on Mental Health
                    </div>

                    <div className="post-subtitle">
                        Social media has become an integral part of our lives, 
                        allowing us to connect, share, and consume...
                    </div>

                    <Link to="/social-impact" className="post-button">
                        Read more
                        <span className="post-btn-icon">{'>'}</span>
                    </Link>
                </div>
            </div>

            <div className="col-md-9 mb-5 mx-auto d-flex flex-wrap">
                <div className="col-md-6 d-flex flex-column post-item">
                    <div className="post-image">
                        <img src={BlogImg3} className="d-block w-100 h-100" alt="..." />
                    </div>

                    <div className="post-title">
                        The Psychology of Procrastination and Effective Strategies to Overcome It
                    </div>

                    <div className="post-subtitle">
                        Procrastination is a common struggle that many people face, 
                        causing delays in tasks and...
                    </div>

                    <Link to="/overcome-procrastination" className="post-button">
                        Read more
                        <span className="post-btn-icon">{'>'}</span>
                    </Link>
                </div>

                <div className="col-md-6 d-flex flex-column post-item">
                    <div className="post-image">
                        <img src={BlogImg4} style={{objectPosition: 'top'}} className="d-block w-100 h-100" alt="..." />
                    </div>

                    <div className="post-title">
                        The Impact of Mindfulness on Stress Reduction and Well-being
                    </div>

                    <div className="post-subtitle">
                        In our fast-paced and demanding world, stress has become a 
                        prevalent issue affecting mental...
                    </div>

                    <Link to="/stress-reduction" className="post-button">
                        Read more
                        <span className="post-btn-icon">{'>'}</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default TrulymadlyMatrimony;