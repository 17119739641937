import BannerImg from "../../images/article-2.webp";

function Article2() {

    return (
        <div>
            <div className="blog-page-layout">
                <div className="page-banner">
                    <img width="100%" src={BannerImg} alt="page-banner" />
                </div>

                <div className="heavy-tagline col-md-9 circularStd-font text-center m-auto pt-4 pb-2">
                    Understanding the Impact of Social Media on Mental Health
                </div>
                <div className="overflow-auto px-4 col-md-9 m-auto">
                    <div className="mt-3 mb-5 page-content">
                        <p>
                            Social media has become an integral part of our lives, allowing us to connect, 
                            share, and consume information. While it has its benefits, excessive use of 
                            social media can have a significant impact on our mental health. Understanding 
                            the potential effects of social media is crucial for maintaining a healthy 
                            relationship with these platforms. Here are some key points to consider:
                        </p>

                        <ol type="1">
                            <li>Social comparison and self-esteem: Social media often presents an idealized 
                                version of others' lives, leading to social comparison. Constant exposure to 
                                carefully curated posts and images can make us feel inadequate and negatively 
                                impact our self-esteem. It is essential to remind ourselves that social media 
                                portrays a filtered reality, and comparison is not an accurate reflection of our worth.
                            </li>

                            <li>Fear of missing out (FOMO): Social media platforms are designed to create a fear of 
                                missing out. Constant updates and notifications can make us feel anxious and 
                                disconnected when we're not actively engaged online. It is crucial to set boundaries 
                                and allocate time for offline activities to maintain a healthy balance.
                            </li>

                            <li>Unrealistic beauty standards: Social media platforms often promote unrealistic beauty 
                                standards, which can contribute to body dissatisfaction and low self-esteem, particularly 
                                among vulnerable populations such as adolescents. Being aware of these idealized 
                                representations and promoting body
                            </li>

                            <li>Cyberbullying and online harassment: The anonymity and distance provided by social media platforms 
                                can facilitate cyberbullying and online harassment. Negative interactions, hate speech, and trolling 
                                can deeply affect mental well-being. It is important to report and block abusive users and seek 
                                support if faced with such situations.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Article2;