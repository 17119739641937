import './styles.scss';
import { Link } from "react-router-dom";
// import FooterLogo from '../../images/footer-logo-web.png';

function Footer() {

    return (
        <div className="footer-section" id="footer-v3">
            <div className="footer-web mx-auto">
                <div className="w-100 d-flex flex-wrap">
                    <div className="col-md-6 p-3">
                        <img width="180" src="https://cdni.trulymadly.com/tm-static-assets-production/web/logo.webp" alt="footer-logo" />
                    </div>
                    <div className="col-md-6 p-3">
                        <div className="pb-1">
                            <a href="mailto:contact@trulymadly.info">contact@trulymadly.info</a>
                        </div>
                        
                        <div>
                            © 2023 Trulymadly All rights reserved
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="footer-mobile px-4 py-4 w-100 d-block d-md-none">
                <div className="w-100 d-flex">
                    <div className="w-50">
                        <div className="footer-text py-1">
                            <Link to="/contact" className="footer-link-v3">
                                Contact
                            </Link>
                        </div>
                        <div className="footer-text py-1">
                            <Link to="/safety-guidelines" className="footer-link-v3">
                                Safety Guidelines
                            </Link>
                        </div>
                    </div>
                    <div className="w-50">
                        <div className="footer-text py-1">
                            <Link to="/privay-policy" className="footer-link-v3">
                                Privacy Policy
                            </Link>
                        </div>
                        <div className="footer-text py-1">
                            <Link to="/terms" className="footer-link-v3">
                                Terms and Conditions
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Footer;