import React from 'react';
import './styles.scss';

function Header() {

    return (
        <React.Fragment>
            <nav className="navbar position-fixed w-100 p-0 bg-white">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src="https://cdni.trulymadly.com/tm-static-assets-production/web/logo.webp" alt="logo" width="120" height="30" />
                    </a>
                </div>
            </nav>
        </React.Fragment>
    );
}

export default Header;